import 'bootstrap/dist/css/bootstrap.min.css';

export default function Header(){
    return(
        <div class="d-flex justify-content-center">
            <h3 class="">
                JavaScript Projects
            </h3>
        </div>
        
    )
}